import { MdArrowBack } from "react-icons/md";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px 0;
  width: 95%;
`;

const Iframe = styled.iframe.attrs({
  src:
    "https://techdog.tomticket.com/formulario?id=EP48136&account=3080704P06092022120558",
})`
  width: 100%;
  height: 100vh;
  border-width: 0px;
  overflow: auto;
  max-width: 600px;
`;

const BoxBackLogin = styled.div`
  display: flex;
  align-items: center;
`;
const iconBack = styled(MdArrowBack)``;

export { Container, Header, Iframe, iconBack, BoxBackLogin };
